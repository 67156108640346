import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import ReactDatePicker from "react-datepicker";
import {
  addCandidateExperience,
  addCandidateProject,
  addCandidateReference,
  BusinessPrinciple,
  CommunicationSkill,
  deleteCandidateExperience,
  deleteCandidateProject,
  getCandidateProfile,
  IReference,
  PeerDescription,
  resetPostingStatus,
  updateCandidateExperience,
  updateCandidateProfile,
  updateCandidateProject,
  peerDescriptionList,
  businessPrincipleList,
  communicationSkillList,
  WorkType,
  Role,
  workTypeList,
  roleList,
  ReferenceRelationship,
  referenceRelationshipList,
  UseCase,
  useCaseList,
  EngagementType,
  IExperience,
  FunctionArea,
  functionAreaList,
  Industry,
  industryList,
  Stage,
  stageList,
  HealthcareIndustry,
  CompanyEmployees,
  healthcareIndustryList,
  companyEmployeesList,
  Skills,
} from "../redux/features/candidateProfileSlice";
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Field,
  Option as FluentOption,
  InfoLabel,
  Input,
  Textarea,
  Skeleton,
  SkeletonItem,
  OptionGroup,
} from "@fluentui/react-components";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  AddSVG,
  ArrowLeftSVG,
  CalendarSVG,
  ClockSVG,
  CloseCircleSVG,
  DocumentTextBoldSVG,
  DollarCircleSVG,
  ExportMissingSVG,
  ExportSVG,
  LinkedInSVG,
  LocationSVG,
  MonitorMobileSVG,
  PeopleSVG,
  ReceiptEditSVG,
  SocialIconSVG,
  TickCircleSVG,
  UserSVG,
  UserTickSVG,
  VerifiedTickSVG,
} from "../assets/svgs";
import Reference from "../elements/Reference";
import Project from "../elements/Project";
import {
  useDialogStyles,
  useFieldStyles,
  useInputStyles,
} from "../fluentStyles";
import { FileUploader } from "react-drag-drop-files";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";

import "react-datepicker/dist/react-datepicker.css";
import localDateToDateString from "../utils/localDateToDateString";
import generateSkillOptionsLists from "../utils/generateSkillOptionsList";

const CandidateProfileEdit = () => {
  const [dataRequested, setDataRequested] = useState(false);
  const candidateStatus = useAppSelector(
    (state) => state.candidateProfile.status,
  );
  const candidate = useAppSelector((state) => state.candidateProfile.candidate);
  const userId = useAppSelector((state) => state.auth.user?.id);
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // Fallback shouldn't happen
  const profileId = candidateId ?? userId ?? "";

  const loading = !dataRequested || candidateStatus !== "succeeded";

  useEffect(() => {
    const controller = new AbortController();

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(
        getCandidateProfile({
          id: profileId,
          token,
          signal: controller.signal,
        }),
      );
      setDataRequested(true);
    })();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Venturous Profile";
  }, []);

  const experience = (
    <Experience
      profileId={profileId}
      experiences={candidate.experiences}
      loading={loading}
    />
  );

  const questionnaire = (
    <Questionnaires
      profileId={profileId}
      businessPrinciples={candidate.questionnaireBusinessPrinciples}
      teams={candidate.questionnaireTeams}
      separatesFromPeers={candidate.questionnaireSeparatesFromPeers}
      peerDescription={candidate.questionnairePeerDescription}
      communicationSkills={candidate.questionnaireCommunicationSkills}
      loading={loading}
    />
  );

  return (
    <>
      <div className="flex flex-col gap-5">
        <Breadcrumb text="Edit My Venturous Profile" />
        <div className="flex gap-5 self-stretch items-stretch">
          <div className="flex flex-col gap-8 self-stretch items-stretch flex-1 w-full lg:max-w-[33%]">
            <NameLocationTitle
              profileId={profileId}
              candidateName={candidate.name}
              profilePictureLink={candidate.profilePictureLink}
              titleAndEmployer={candidate.titleAndEmployer}
              yearsOfExperience={candidate.yearsOfExperience}
              location={candidate.location}
              loading={loading}
            />
            <div className="flex flex-col gap-5 items-stretch">
              <Biography
                profileId={profileId}
                biography={candidate.biography}
                loading={loading}
              />
              <IdealEngagement
                profileId={profileId}
                idealWorkType={candidate.idealWorkType}
                idealRoles={candidate.idealRoles}
                idealSubindustries={candidate.idealSubindustries}
                idealUseCase={candidate.idealUseCase}
                idealCompanyHeadcount={candidate.idealCompanyHeadcount}
                topSkills={candidate.topSkills}
                secondarySkills={candidate.secondarySkills}
                additionalSkills={candidate.additionalSkills}
                loading={loading}
              />
              <CoreCompetencies
                profileId={profileId}
                roles={candidate.idealRoles}
                topSkills={candidate.topSkills}
                secondarySkills={candidate.secondarySkills}
                additionalSkills={candidate.additionalSkills}
                loading={loading}
              />
              <Availability
                profileId={profileId}
                availabilityFullTime={candidate.availabilityFullTime}
                availabilityFractional={candidate.availabilityFractional}
                availabilityAdvisory={candidate.availabilityAdvisory}
                availabilityBoard={candidate.availabilityBoard}
                availabilityTime={candidate.availabilityTime}
                availabilityStartDate={candidate.availabilityStartDate}
                loading={loading}
              />
              <Pricing
                profileId={profileId}
                retainerDaily={candidate.retainerDaily}
                retainerHourly={candidate.retainerHourly}
                loading={loading}
              />
              <Links
                profileId={profileId}
                linkedIn={candidate.linkedIn}
                resumeLink={candidate.resumeLink}
                personalWebsite={candidate.personalWebsite}
                cultureLink={candidate.cultureLink}
                loading={loading}
              />
              <div className="lg:hidden">{experience}</div>
              <div className="lg:hidden">{questionnaire}</div>
              <References
                profileId={profileId}
                references={candidate.references}
                candidateName={candidate.name}
              />
              <Projects profileId={profileId} projects={candidate.projects} />
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-5 self-stretch items-start flex-[2_0_0%]">
            {experience}
            {questionnaire}
          </div>
        </div>
      </div>
    </>
  );
};

const Breadcrumb = ({ text }: { text: string }) => {
  return <div className="text-sm font-medium text-[#727E94]">{text}</div>;
};

const NameLocationTitle = ({
  profileId,
  candidateName,
  profilePictureLink,
  titleAndEmployer,
  yearsOfExperience,
  location,
  loading,
}: {
  profileId: string;
  candidateName?: string;
  profilePictureLink?: string;
  titleAndEmployer?: string;
  yearsOfExperience?: number;
  location?: string;
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <Skeleton className="card flex-row gap-4 w-full">
        <div className="flex flex-col items-start rounded-full relative">
          <SkeletonItem
            shape="circle"
            style={{ height: "88px", width: "88px" }}
          />
        </div>
        <div className="flex flex-col items-start gap-1 flex-1">
          <div className="flex flex-col gap-[2px] items-start">
            <SkeletonItem style={{ height: "32px", width: "150px" }} />
            <SkeletonItem style={{ height: "20px", width: "100px" }} />
            <SkeletonItem style={{ height: "20px", width: "100px" }} />
          </div>
          <SkeletonItem style={{ height: "24px", width: "100px" }} />
        </div>
      </Skeleton>
    );
  }

  return (
    <div className="card flex-row gap-4 w-full">
      <div className="flex flex-col items-start rounded-full relative">
        <Avatar
          image={{ src: profilePictureLink }}
          name={candidateName}
          size={72}
          style={{ width: "88px", height: "88px" }}
        />
        <VerifiedTickSVG className="absolute bottom-0 right-0" />
      </div>
      <div className="flex flex-col items-start gap-1 flex-1">
        <div className="flex flex-col gap-[2px] items-start">
          <p className="text-2xl font-semibold tracking-[-0.24px] text-[#22234A]">
            {candidateName}
          </p>
          <p className="text-sm font-medium text-[#727E94]">
            {titleAndEmployer}
          </p>
          {yearsOfExperience ? (
            <p className="text-sm font-medium text-[#727E94]">
              {`${yearsOfExperience} ${yearsOfExperience === 1 ? "year" : "years"} of experience`}
            </p>
          ) : null}
        </div>
        {location ? (
          <div className="flex items-center gap-1">
            <LocationSVG />
            <p className="text-base font-medium text-[#727E94]">{location}</p>
          </div>
        ) : null}
      </div>
      <p className="edit-text self-end" onClick={() => setEditModalOpen(true)}>
        Edit
      </p>
      <NameLocationTitleEditDialog
        profileId={profileId}
        candidateName={candidateName}
        profilePictureLink={profilePictureLink}
        titleAndEmployer={titleAndEmployer}
        yearsOfExperience={yearsOfExperience}
        location={location}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const NameLocationTitleEditDialog = ({
  profileId,
  candidateName,
  profilePictureLink,
  titleAndEmployer,
  yearsOfExperience,
  location,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  candidateName?: string;
  profilePictureLink?: string;
  titleAndEmployer?: string;
  yearsOfExperience?: number;
  location?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newCandidateName, setNewCandidateName] = useState("");
  const [newTitleAndEmployer, setNewTitleAndEmployer] = useState("");
  const [newYearsOfExperience, setNewYearsOfExperience] = useState("0");
  const [newLocation, setNewLocation] = useState("");
  const [newProfilePicture, setNewProfilePicture] = useState<File>();

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewCandidateName(candidateName ?? "");
    setNewTitleAndEmployer(titleAndEmployer ?? "");
    setNewYearsOfExperience(yearsOfExperience?.toString() ?? "0");
    setNewLocation(location ?? "");
    setNewProfilePicture(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = new FormData();
    emails.forEach((email) => data.append("emails[]", email));
    data.append("candidateName", newCandidateName);
    data.append("titleAndEmployer", newTitleAndEmployer);
    data.append("location", newLocation);
    if (newYearsOfExperience) {
      data.append("yearsOfExperience", newYearsOfExperience);
    }

    if (newProfilePicture) {
      data.append("profilePicture", newProfilePicture);
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Personal Information
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Name" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newCandidateName}
                  onChange={(_evt, { value }) => setNewCandidateName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Profile Picture" className={fieldStyle.editField}>
                <div
                  className="flex w-[100px] h-[100px] self-center justify-center items-center border-slate border-solid border-2"
                  style={{
                    backgroundImage: `url(${newProfilePicture ? URL.createObjectURL(newProfilePicture) : profilePictureLink})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <FileUploader
                  classes="flex-1 !min-w-[200px]"
                  multiple={false}
                  handleChange={(file?: File) => setNewProfilePicture(file)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Location" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newLocation}
                  onChange={(_evt, { value }) => setNewLocation(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Headline" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="Ex-Microsoft CEO"
                  value={newTitleAndEmployer}
                  onChange={(_evt, { value }) => setNewTitleAndEmployer(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Years of Experience"
                className={fieldStyle.editField}
              >
                <Input
                  type="number"
                  min={0}
                  value={newYearsOfExperience}
                  onChange={(_evt, { value }) => setNewYearsOfExperience(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Biography = ({
  profileId,
  biography,
  loading,
}: {
  profileId: string;
  biography?: string;
  loading: boolean;
}) => {
  const ref = useRef(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const MAX_LINES = 6;
  const MAX_LINES_CLASS = "line-clamp-6";

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetHeight, scrollHeight } = ref.current;

      if (readMore) {
        // If readMore is true, check how many lines of text there are
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const lines = offsetHeight / lineHeight;

        // If there are more than MAX_LINES, then everything is fine
        if (lines > MAX_LINES) {
          return;
        } else {
          // If not, the text is not being truncated anyway and we can reset
          setIsTruncated(false);
          setReadMore(false);
        }
      } else if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        // If readMore is not true, then check if the text is being cut off by the line clamp
        setIsTruncated(true);
        setReadMore(false);
      } else {
        // In all other cases, reset
        setIsTruncated(false);
        setReadMore(false);
      }
    }
  }, [ref, readMore, biography]);

  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardEditHeader text="Bio" />
        <SkeletonItem style={{ height: "128px", width: "100%" }} />
      </Skeleton>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader text="Bio" onClick={() => setEditModalOpen(true)} />
      <div className="p-3 self-stretch bg-[#F2F5F5] rounded-xl">
        <p
          ref={ref}
          className={`text-sm font-medium text-[#4E4F6C] whitespace-break-spaces ${readMore ? "" : MAX_LINES_CLASS}`}
        >
          {biography}
        </p>
        {!isTruncated ? null : readMore ? (
          <p className="edit-text w-fit" onClick={() => setReadMore(false)}>
            View less
          </p>
        ) : (
          <p className="edit-text w-fit" onClick={() => setReadMore(true)}>
            View more
          </p>
        )}
      </div>
      <BiographyEditDialog
        profileId={profileId}
        biography={biography}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const BiographyEditDialog = ({
  profileId,
  biography,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  biography?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newBiography, setNewBiography] = useState("");

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  useEffect(() => {
    setNewBiography(biography ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = {
      emails,
      biography: newBiography,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Biography
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Biography" className={fieldStyle.editField}>
                <Textarea
                  className={inputStyle.largeTextarea}
                  value={newBiography}
                  onChange={(_evt, data) => setNewBiography(data.value)}
                  resize="vertical"
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const IdealEngagement = ({
  profileId,
  idealWorkType,
  idealRoles,
  idealSubindustries,
  idealUseCase,
  idealCompanyHeadcount,
  topSkills,
  secondarySkills,
  additionalSkills,
  loading,
}: {
  profileId: string;
  idealWorkType: WorkType[];
  idealRoles: Role[];
  idealSubindustries: HealthcareIndustry[];
  idealUseCase: UseCase[];
  idealCompanyHeadcount: CompanyEmployees[];
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="card gap-3 w-full">
        <CardEditHeader text="Ideal engagement" />
        <div className="flex flex-col content-center items-start self-stretch">
          <ContentRow text="Role" response="None" loading />
          <ContentRow text="Type of Work" response="None" loading />
          <ContentRow text="Healthcare Area" response="None" loading />
          <ContentRow text="Specialty" response="None" loading />
          <ContentRow text="Company Headcount" response="None" isLast loading />
        </div>
        <IdealEngagementEditDialog
          profileId={profileId}
          idealWorkType={idealWorkType}
          idealRoles={idealRoles}
          idealSubindustries={idealSubindustries}
          idealUseCase={idealUseCase}
          idealCompanyHeadcount={idealCompanyHeadcount}
          topSkills={topSkills}
          secondarySkills={secondarySkills}
          additionalSkills={additionalSkills}
          isOpen={editModalOpen}
          setOpenStateFn={setEditModalOpen}
        />
      </div>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader
        text="Ideal engagement"
        onClick={() => setEditModalOpen(true)}
        isInternal
      />
      <div className="flex flex-col content-center items-start self-stretch">
        <ContentRow text="Role" response={idealRoles.join(", ") || "None"} />
        <ContentRow
          text="Type of Work"
          response={idealWorkType.join(", ") || "None"}
        />
        <ContentRow
          text="Healthcare Area"
          response={idealSubindustries.join(", ") || "None"}
        />
        <ContentRow
          text="Specialty"
          response={idealUseCase.join(", ") || "None"}
        />
        <ContentRow
          text="Company Headcount"
          response={idealCompanyHeadcount.join(", ") || "None"}
          isLast
        />
      </div>
      <IdealEngagementEditDialog
        profileId={profileId}
        idealWorkType={idealWorkType}
        idealRoles={idealRoles}
        idealSubindustries={idealSubindustries}
        idealUseCase={idealUseCase}
        idealCompanyHeadcount={idealCompanyHeadcount}
        topSkills={topSkills}
        secondarySkills={secondarySkills}
        additionalSkills={additionalSkills}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const IdealEngagementEditDialog = ({
  profileId,
  idealWorkType,
  idealRoles,
  idealSubindustries,
  idealUseCase,
  idealCompanyHeadcount,
  topSkills,
  secondarySkills,
  additionalSkills,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  idealWorkType: WorkType[];
  idealRoles: Role[];
  idealSubindustries: HealthcareIndustry[];
  idealUseCase: UseCase[];
  idealCompanyHeadcount: CompanyEmployees[];
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newIdealWorkType, setNewIdealWorkType] = useState<WorkType[]>([]);
  const [newIdealRoles, setNewIdealRoles] = useState<Role[]>([]);
  const [newIdealSubindustries, setNewIdealSubindustries] = useState<
    HealthcareIndustry[]
  >([]);
  const [newIdealUseCase, setNewIdealUseCase] = useState<UseCase[]>([]);
  const [newIdealCompanyHeadcount, setNewIdealCompanyHeadcount] = useState<
    CompanyEmployees[]
  >([]);

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewIdealWorkType([...idealWorkType]);
    setNewIdealRoles([...idealRoles]);
    setNewIdealSubindustries([...idealSubindustries]);
    setNewIdealUseCase([...idealUseCase]);
    setNewIdealCompanyHeadcount([...idealCompanyHeadcount]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const { skillsList } = generateSkillOptionsLists({
      roles: newIdealRoles,
      list: "top",
      topSkills,
      secondarySkills,
      additionalSkills,
      maxSelections: 5,
    });

    const data = {
      emails,
      idealWorkType: newIdealWorkType,
      idealRoles: newIdealRoles,
      idealSubindustries: newIdealSubindustries,
      idealUseCase: newIdealUseCase,
      idealCompanyHeadcount: newIdealCompanyHeadcount,
      // Remove any skills that are no longer applicable
      topSkills: topSkills.filter((skill) => skillsList.includes(skill)),
      secondarySkills: secondarySkills.filter((skill) =>
        skillsList.includes(skill),
      ),
      additionalSkills: additionalSkills.filter((skill) =>
        skillsList.includes(skill),
      ),
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent, list: Role[]): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = list.indexOf(active.id as Role);
      const newIndex = list.indexOf(over.id as Role);

      setNewIdealRoles(arrayMove(list, oldIndex, newIndex));
    }
  };

  const industryOptionGenerator = (industry: HealthcareIndustry) => (
    <FluentOption
      key={`industry-${industry}`}
      disabled={
        newIdealSubindustries.length >= 5 &&
        !newIdealSubindustries.includes(industry)
      }
    >
      {industry}
    </FluentOption>
  );

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Ideal Engagement
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={<InfoLabel info="Choose up to 3">Ideal Roles</InfoLabel>}
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newIdealRoles.join(", ")}
                  selectedOptions={newIdealRoles}
                  onOptionSelect={(_evt, data) =>
                    setNewIdealRoles(data.selectedOptions as Role[])
                  }
                  multiselect
                >
                  {roleList.map((role) => (
                    <FluentOption
                      key={`role-${role}`}
                      disabled={
                        !newIdealRoles.includes(role) &&
                        newIdealRoles.length >= 3
                      }
                    >
                      {role}
                    </FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={
                  <div className="flex flex-col gap-1">
                    Ranked Ideal Roles
                    <p className="text-xs font-semibold tracking-[-0.18px] text-[#727E94]">
                      Drag to re-order
                    </p>
                  </div>
                }
                className={fieldStyle.editField}
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) => handleDragEnd(event, newIdealRoles)}
                >
                  <SortableContext
                    items={newIdealRoles}
                    strategy={verticalListSortingStrategy}
                  >
                    {newIdealRoles.map((id, idx) => (
                      <SortableItem key={id} id={id} idx={idx} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Ideal Type of Work"
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newIdealWorkType.join(", ")}
                  selectedOptions={newIdealWorkType}
                  onOptionSelect={(_evt, data) =>
                    setNewIdealWorkType(data.selectedOptions as WorkType[])
                  }
                  multiselect
                >
                  {workTypeList.map((type) => (
                    <FluentOption key={`work-type-${type}`}>
                      {type}
                    </FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={
                  <InfoLabel info="Choose up to 5">
                    Ideal Healthcare Industry
                  </InfoLabel>
                }
                className={fieldStyle.editField}
              >
                <Dropdown
                  selectedOptions={newIdealSubindustries}
                  value={newIdealSubindustries.join(", ")}
                  onOptionSelect={(_evt, data) =>
                    setNewIdealSubindustries(
                      data.selectedOptions as HealthcareIndustry[],
                    )
                  }
                  multiselect
                >
                  <OptionGroup label="Clinical Services">
                    {healthcareIndustryList
                      .slice(0, 5)
                      .map(industryOptionGenerator)}
                  </OptionGroup>
                  <OptionGroup label="Technology and Data">
                    {healthcareIndustryList
                      .slice(5, 10)
                      .map(industryOptionGenerator)}
                  </OptionGroup>
                  <OptionGroup label="Specialty Care">
                    {healthcareIndustryList
                      .slice(10, 16)
                      .map(industryOptionGenerator)}
                  </OptionGroup>
                  <OptionGroup label="Healthcare Administration">
                    {healthcareIndustryList
                      .slice(16, 20)
                      .map(industryOptionGenerator)}
                  </OptionGroup>
                  <OptionGroup label="Management and Engagement">
                    {healthcareIndustryList
                      .slice(20, 24)
                      .map(industryOptionGenerator)}
                  </OptionGroup>
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={
                  <InfoLabel info="Choose up to 3">Ideal Specialty</InfoLabel>
                }
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newIdealUseCase.join(", ")}
                  selectedOptions={newIdealUseCase}
                  onOptionSelect={(_evt, data) =>
                    setNewIdealUseCase(data.selectedOptions as UseCase[])
                  }
                  multiselect
                >
                  {useCaseList.map((useCase) => (
                    <FluentOption
                      key={`use-case-${useCase}`}
                      disabled={
                        !newIdealUseCase.includes(useCase) &&
                        newIdealUseCase.length >= 3
                      }
                    >
                      {useCase}
                    </FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={
                  <InfoLabel info="Choose up to 3">
                    Ideal Company Size
                  </InfoLabel>
                }
                className={fieldStyle.editField}
              >
                <Dropdown
                  selectedOptions={newIdealCompanyHeadcount}
                  value={newIdealCompanyHeadcount.join(", ")}
                  onOptionSelect={(_evt, data) =>
                    setNewIdealCompanyHeadcount(
                      data.selectedOptions as CompanyEmployees[],
                    )
                  }
                  multiselect
                >
                  {companyEmployeesList.map((size) => (
                    <FluentOption
                      key={`size-${size}`}
                      disabled={
                        newIdealCompanyHeadcount.length >= 3 &&
                        !newIdealCompanyHeadcount.includes(size)
                      }
                    >
                      {size}
                    </FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const CoreCompetencies = ({
  profileId,
  roles,
  topSkills,
  secondarySkills,
  additionalSkills,
  loading,
}: {
  profileId: string;
  roles: Role[];
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="card gap-3 w-full">
        <CardEditHeader text="Ideal engagement" />
        <div className="flex flex-col content-center items-start self-stretch">
          <ContentRow text="Top Skills" response="None" loading />
          <ContentRow text="Secondary Skills" response="None" loading />
          <ContentRow text="Additional Skills" response="None" loading />
        </div>
        <CoreCompetenciesEditDialog
          profileId={profileId}
          roles={roles}
          topSkills={topSkills}
          secondarySkills={secondarySkills}
          additionalSkills={additionalSkills}
          isOpen={editModalOpen}
          setOpenStateFn={setEditModalOpen}
        />
      </div>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader
        text="Core Competencies"
        onClick={() => setEditModalOpen(true)}
        isInternal
      />
      <div className="flex flex-col content-center items-start self-stretch">
        <ContentRow
          text="Top Skills"
          response={topSkills.join(", ") || "None"}
        />
        <ContentRow
          text="Secondary Skills"
          response={secondarySkills.join(", ") || "None"}
        />
        <ContentRow
          text="Additional Skills"
          response={additionalSkills.join(", ") || "None"}
        />
      </div>
      <CoreCompetenciesEditDialog
        profileId={profileId}
        roles={roles}
        topSkills={topSkills}
        secondarySkills={secondarySkills}
        additionalSkills={additionalSkills}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const CoreCompetenciesEditDialog = ({
  profileId,
  roles,
  topSkills,
  secondarySkills,
  additionalSkills,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  roles: Role[];
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newTopSkills, setNewTopSkills] = useState<Skills[]>([]);
  const [newSecondarySkills, setNewSecondarySkills] = useState<Skills[]>([]);
  const [newAdditionalSkills, setNewAdditionalSkills] = useState<Skills[]>([]);

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewTopSkills([...topSkills]);
    setNewSecondarySkills([...secondarySkills]);
    setNewAdditionalSkills([...additionalSkills]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = {
      emails,
      topSkills: newTopSkills,
      secondarySkills: newSecondarySkills,
      additionalSkills: newAdditionalSkills,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  const { options: topSkillsOptions, skillsList } = generateSkillOptionsLists({
    roles,
    list: "top",
    topSkills: newTopSkills,
    secondarySkills: newSecondarySkills,
    additionalSkills: newAdditionalSkills,
    maxSelections: 5,
  });

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Core Competencies
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={`Choose your 5 top skills out of ${skillsList.length} `}
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newTopSkills.join(", ")}
                  selectedOptions={newTopSkills ?? ""}
                  onOptionSelect={(_evt, data) => {
                    setNewTopSkills(data.selectedOptions as Skills[]);
                    setNewSecondarySkills(
                      newSecondarySkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                    setNewAdditionalSkills(
                      newAdditionalSkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                  }}
                  multiselect
                >
                  {topSkillsOptions}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={`Choose your 5 secondary skills out of ${skillsList.length} `}
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newSecondarySkills.join(", ")}
                  selectedOptions={newSecondarySkills ?? ""}
                  onOptionSelect={(_evt, data) => {
                    setNewTopSkills(
                      newTopSkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                    setNewSecondarySkills(data.selectedOptions as Skills[]);
                    setNewAdditionalSkills(
                      newAdditionalSkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                  }}
                  multiselect
                >
                  {
                    generateSkillOptionsLists({
                      roles,
                      list: "secondary",
                      topSkills: newTopSkills,
                      secondarySkills: newSecondarySkills,
                      additionalSkills: newAdditionalSkills,
                      maxSelections: 5,
                    }).options
                  }
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label={`Choose your 10 additional skills out of ${skillsList.length} `}
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newAdditionalSkills.join(", ")}
                  selectedOptions={newAdditionalSkills ?? ""}
                  onOptionSelect={(_evt, data) => {
                    setNewTopSkills(
                      newTopSkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                    setNewSecondarySkills(
                      newSecondarySkills.filter(
                        (skill) => !data.selectedOptions.includes(skill),
                      ),
                    );
                    setNewAdditionalSkills(data.selectedOptions as Skills[]);
                  }}
                  multiselect
                >
                  {
                    generateSkillOptionsLists({
                      roles,
                      list: "additional",
                      topSkills: newTopSkills,
                      secondarySkills: newSecondarySkills,
                      additionalSkills: newAdditionalSkills,
                      maxSelections: 10,
                    }).options
                  }
                </Dropdown>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Availability = ({
  profileId,
  availabilityFullTime,
  availabilityFractional,
  availabilityAdvisory,
  availabilityBoard,
  availabilityTime,
  availabilityStartDate,
  loading,
}: {
  profileId: string;
  availabilityFullTime?: boolean;
  availabilityFractional?: boolean;
  availabilityAdvisory?: boolean;
  availabilityBoard?: boolean;
  availabilityTime?: string;
  availabilityStartDate?: string;
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardEditHeader text="Availability" />
        <div className="flex flex-col content-center items-start self-stretch">
          <ContentRow
            Icon={<ClockSVG className="flex-shrink-0" height="24" width="24" />}
            text="Part-Time / Fractional Roles"
            loading
          />
          <ContentRow
            Icon={<UserSVG className="flex-shrink-0" height="24" width="24" />}
            text="Advisory Roles"
            loading
          />
          <ContentRow
            Icon={
              <PeopleSVG className="flex-shrink-0" height="24" width="24" />
            }
            text="Board Roles"
            loading
          />
          <ContentRow
            Icon={<ReceiptEditSVG className="flex-shrink-0" />}
            text="Full-time Roles"
            loading
          />
          <ContentRow
            Icon={<CalendarSVG className="flex-shrink-0" />}
            text="Availability"
            response="Unknown"
            loading
          />
          <ContentRow
            Icon={<UserTickSVG className="flex-shrink-0" />}
            text="Available start date"
            response="Unknown"
            loading
            isLast
          />
        </div>
      </Skeleton>
    );
  }

  let startDateString;
  const startDate = availabilityStartDate
    ? dateStringToLocalDate(availabilityStartDate)
    : null;
  if (!startDate) {
    startDateString = "Unknown";
  } else if (startDate < new Date()) {
    startDateString = "Immediately";
  } else {
    startDateString = startDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader
        text="Availability"
        onClick={() => setEditModalOpen(true)}
      />
      <div className="flex flex-col content-center items-start self-stretch">
        {/* <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between"> */}
        <ContentRow
          Icon={<ClockSVG className="flex-shrink-0" height="24" width="24" />}
          text="Part-Time / Fractional Roles"
          responseIcon={
            availabilityFractional ? (
              <TickCircleSVG height="24" width="24" />
            ) : (
              <CloseCircleSVG height="24" width="24" />
            )
          }
        />
        <ContentRow
          Icon={<UserSVG className="flex-shrink-0" height="24" width="24" />}
          text="Advisory Roles"
          responseIcon={
            availabilityAdvisory ? (
              <TickCircleSVG height="24" width="24" />
            ) : (
              <CloseCircleSVG height="24" width="24" />
            )
          }
        />
        <ContentRow
          Icon={<PeopleSVG className="flex-shrink-0" height="24" width="24" />}
          text="Board Roles"
          responseIcon={
            availabilityBoard ? (
              <TickCircleSVG height="24" width="24" />
            ) : (
              <CloseCircleSVG height="24" width="24" />
            )
          }
        />
        <ContentRow
          Icon={<ReceiptEditSVG className="flex-shrink-0" />}
          text="Full-time Roles"
          responseIcon={
            availabilityFullTime ? (
              <TickCircleSVG height="24" width="24" />
            ) : (
              <CloseCircleSVG height="24" width="24" />
            )
          }
        />
        {/* </div> */}
        <ContentRow
          Icon={<CalendarSVG className="flex-shrink-0" />}
          text="Availability"
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          response={
            availabilityTime ? `${availabilityTime} hrs/week` : "Unknown"
          }
        />
        <ContentRow
          Icon={<UserTickSVG className="flex-shrink-0" />}
          text="Available start date"
          response={startDateString}
          isLast
        />
      </div>
      <AvailabilityEditDialog
        profileId={profileId}
        availabilityFullTime={availabilityFullTime}
        availabilityFractional={availabilityFractional}
        availabilityAdvisory={availabilityAdvisory}
        availabilityBoard={availabilityBoard}
        availabilityTime={availabilityTime}
        availabilityStartDate={availabilityStartDate}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const AvailabilityEditDialog = ({
  profileId,
  availabilityFullTime,
  availabilityFractional,
  availabilityAdvisory,
  availabilityBoard,
  availabilityTime,
  availabilityStartDate,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  availabilityFullTime?: boolean;
  availabilityFractional?: boolean;
  availabilityAdvisory?: boolean;
  availabilityBoard?: boolean;
  availabilityTime?: string;
  availabilityStartDate?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newAvailabilityFullTime, setNewAvailabilityFullTime] = useState(false);
  const [newAvailabilityFractional, setNewAvailabilityFractional] =
    useState(false);
  const [newAvailabilityAdvisory, setNewAvailabilityAdvisory] = useState(false);
  const [newAvailabilityBoard, setNewAvailabilityBoard] = useState(false);
  const [newAvailabilityTime, setNewAvailabilityTime] = useState("2");
  const [newAvailabilityStartDate, setNewAvailabilityStartDate] = useState("");

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewAvailabilityFullTime(availabilityFullTime ?? false);
    setNewAvailabilityFractional(availabilityFractional ?? false);
    setNewAvailabilityAdvisory(availabilityAdvisory ?? false);
    setNewAvailabilityBoard(availabilityBoard ?? false);
    setNewAvailabilityTime(availabilityTime ?? "2");
    setNewAvailabilityStartDate(availabilityStartDate ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = {
      emails,
      availabilityFullTime: newAvailabilityFullTime,
      availabilityFractional: newAvailabilityFractional,
      availabilityAdvisory: newAvailabilityAdvisory,
      availabilityBoard: newAvailabilityBoard,
      availabilityTime: newAvailabilityTime,
      ...(new Date(newAvailabilityStartDate).toString() !== "Invalid Date"
        ? {
            availabilityStartDate: newAvailabilityStartDate,
          }
        : {}),
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Availability
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Part-Time / Fractional Roles"
                className={fieldStyle.editField}
              >
                <Checkbox
                  checked={newAvailabilityFractional}
                  onChange={(_evt, { checked }) =>
                    setNewAvailabilityFractional(checked as boolean)
                  }
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Advisory Roles" className={fieldStyle.editField}>
                <Checkbox
                  checked={newAvailabilityAdvisory}
                  onChange={(_evt, { checked }) =>
                    setNewAvailabilityAdvisory(checked as boolean)
                  }
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Board Roles" className={fieldStyle.editField}>
                <Checkbox
                  checked={newAvailabilityBoard}
                  onChange={(_evt, { checked }) =>
                    setNewAvailabilityBoard(checked as boolean)
                  }
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Full-time Roles" className={fieldStyle.editField}>
                <Checkbox
                  checked={newAvailabilityFullTime}
                  onChange={(_evt, { checked }) =>
                    setNewAvailabilityFullTime(checked as boolean)
                  }
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Availability (hours/week)"
                className={fieldStyle.editField}
              >
                <Input
                  type="text"
                  value={newAvailabilityTime}
                  onChange={(_evt, { value }) => setNewAvailabilityTime(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Available Start Date"
                className={fieldStyle.editField}
              >
                <input
                  type="date"
                  value={newAvailabilityStartDate}
                  onChange={(evt) =>
                    setNewAvailabilityStartDate(evt.target.value)
                  }
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Pricing = ({
  profileId,
  retainerDaily,
  retainerHourly,
  loading,
}: {
  profileId: string;
  retainerDaily?: string;
  retainerHourly?: string;
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardEditHeader text="Compensation" isInternal />
        <div className="flex flex-col content-center items-start self-stretch">
          <ContentRow
            Icon={<DollarCircleSVG className="flex-shrink-0" />}
            text="8 Hours per Week Monthly Retainer"
            response="Unknown"
            loading
          />
          <ContentRow
            Icon={<DollarCircleSVG className="flex-shrink-0" />}
            text="Hourly Rate"
            response="Unknown"
            isLast
            loading
          />
        </div>
        <PricingEditDialog
          profileId={profileId}
          retainerDaily={retainerDaily}
          retainerHourly={retainerHourly}
          isOpen={editModalOpen}
          setOpenStateFn={setEditModalOpen}
        />
      </Skeleton>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader
        text="Compensation"
        onClick={() => setEditModalOpen(true)}
        isInternal
      />
      <div className="flex flex-col content-center items-start self-stretch">
        <ContentRow
          Icon={<DollarCircleSVG className="flex-shrink-0" />}
          text="8 Hours per Week Monthly Retainer"
          response={retainerDaily ? `${retainerDaily} USD` : "Unknown"}
        />
        <ContentRow
          Icon={<DollarCircleSVG className="flex-shrink-0" />}
          text="Hourly Rate"
          response={retainerHourly ? `${retainerHourly} USD` : "Unknown"}
          isLast
        />
      </div>
      <PricingEditDialog
        profileId={profileId}
        retainerDaily={retainerDaily}
        retainerHourly={retainerHourly}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const PricingEditDialog = ({
  profileId,
  retainerDaily,
  retainerHourly,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  retainerDaily?: string;
  retainerHourly?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newRetainerDaily, setNewRetainerDaily] = useState("");
  const [newRetainerHourly, setNewRetainerHourly] = useState("");

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewRetainerDaily(retainerDaily ?? "");
    setNewRetainerHourly(retainerHourly ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = {
      emails,
      retainerDaily: newRetainerDaily,
      retainerHourly: newRetainerHourly,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Compensation
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="8 Hours Per Week Monthly Retainer (USD)"
                className={fieldStyle.editField}
              >
                <Input
                  type="text"
                  value={newRetainerDaily}
                  onChange={(_evt, { value }) => setNewRetainerDaily(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Hourly Rate (USD)" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newRetainerHourly}
                  onChange={(_evt, { value }) => setNewRetainerHourly(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Links = ({
  profileId,
  linkedIn,
  resumeLink,
  personalWebsite,
  cultureLink,
  loading,
}: {
  profileId: string;
  linkedIn?: string;
  resumeLink?: string;
  personalWebsite?: string;
  cultureLink?: string;
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardEditHeader text="Links" />
        <div className="flex flex-col content-center items-start self-stretch">
          <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between border-bottom-line">
            <ContentRow
              Icon={
                <DocumentTextBoldSVG
                  className="flex-shrink-0"
                  height="20"
                  width="20"
                />
              }
              text="Resume"
              loading
              isLast
            />
            <ContentRow
              Icon={
                <SocialIconSVG
                  className="flex-shrink-0"
                  height="20"
                  width="20"
                />
              }
              text="Culture"
              loading
              isLast
            />
          </div>
          <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between">
            <ContentRow
              Icon={
                <MonitorMobileSVG
                  className="flex-shrink-0"
                  height="20"
                  width="20"
                />
              }
              text="Personal Website"
              loading
              isLast
            />
            <ContentRow
              Icon={
                <LinkedInSVG className="flex-shrink-0" height="20" width="20" />
              }
              text="Linkedin"
              loading
              isLast
            />
          </div>
        </div>
      </Skeleton>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader text="Links" onClick={() => setEditModalOpen(true)} />
      <div className="flex flex-col content-center items-start self-stretch">
        <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between border-bottom-line">
          <ContentRow
            Icon={
              <DocumentTextBoldSVG
                className="flex-shrink-0"
                height="20"
                width="20"
              />
            }
            text="Resume"
            responseIcon={
              resumeLink ? (
                <a
                  className="cursor-pointer"
                  href={resumeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExportSVG height="20" width="20" stroke="#ED5C38" />
                </a>
              ) : (
                <ExportMissingSVG height="20" width="20" />
              )
            }
            isLast
          />
          <ContentRow
            Icon={
              <SocialIconSVG className="flex-shrink-0" height="20" width="20" />
            }
            text="Culture"
            responseIcon={
              cultureLink ? (
                <a
                  className="cursor-pointer"
                  href={cultureLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExportSVG height="20" width="20" stroke="#ED5C38" />
                </a>
              ) : (
                <ExportMissingSVG height="20" width="20" />
              )
            }
            isLast
          />
        </div>
        <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between">
          <ContentRow
            Icon={
              <MonitorMobileSVG
                className="flex-shrink-0"
                height="20"
                width="20"
              />
            }
            text="Personal Website"
            responseIcon={
              personalWebsite ? (
                <a
                  className="cursor-pointer"
                  href={personalWebsite}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExportSVG height="20" width="20" stroke="#ED5C38" />
                </a>
              ) : (
                <ExportMissingSVG height="20" width="20" />
              )
            }
            isLast
          />
          <ContentRow
            Icon={
              <LinkedInSVG className="flex-shrink-0" height="20" width="20" />
            }
            text="Linkedin"
            responseIcon={
              linkedIn ? (
                <a
                  className="cursor-pointer"
                  href={linkedIn}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExportSVG height="20" width="20" stroke="#ED5C38" />
                </a>
              ) : (
                <ExportMissingSVG height="20" width="20" />
              )
            }
            isLast
          />
        </div>
      </div>
      <LinksEditDialog
        profileId={profileId}
        linkedIn={linkedIn}
        resumeLink={resumeLink}
        personalWebsite={personalWebsite}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const LinksEditDialog = ({
  profileId,
  linkedIn,
  personalWebsite,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  linkedIn?: string;
  resumeLink?: string;
  personalWebsite?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newLinkedIn, setNewLinkedIn] = useState("");
  const [newPersonalWebsite, setNewPersonalWebsite] = useState("");
  const [newResume, setNewResume] = useState<File>();

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewLinkedIn(linkedIn ?? "");
    setNewPersonalWebsite(personalWebsite ?? "");
    setNewResume(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = new FormData();
    emails.forEach((email) => data.append("emails[]", email));
    data.append("linkedIn", newLinkedIn);
    data.append("personalWebsite", newPersonalWebsite);

    if (newResume) {
      data.append("resume", newResume);
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Links
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Resume" className={fieldStyle.editField}>
                <FileUploader
                  classes="flex-1 !min-w-[200px] !max-w-[9999px]"
                  multiple={false}
                  handleChange={(file?: File) => setNewResume(file)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Personal Website" className={fieldStyle.editField}>
                <Input
                  type="url"
                  placeholder="www.mywebsite.com"
                  value={newPersonalWebsite}
                  onChange={(_evt, { value }) => setNewPersonalWebsite(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="LinkedIn" className={fieldStyle.editField}>
                <Input
                  type="url"
                  placeholder="www.linkedin.com/in/me"
                  value={newLinkedIn}
                  onChange={(_evt, { value }) => setNewLinkedIn(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const References = ({
  profileId,
  references,
  candidateName,
}: {
  profileId: string;
  references?: IReference[];
  candidateName?: string;
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const sortedReferences = [...(references ?? [])].sort((a, b) =>
    a.status === "Approved" && b.status !== "Approved"
      ? -1 // Order complete references first
      : b.status === "Approved" && a.status !== "Approved"
        ? 1
        : a.createdAt < b.createdAt // Otherwise, order most recent first
          ? 1
          : -1,
  );

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader text="References" />
      {sortedReferences?.map((reference, idx) => (
        <Reference
          key={`reference-${reference.id ?? idx}`}
          {...reference}
          candidateName={candidateName}
        />
      ))}
      <div
        className="flex gap-2 py-2 px-4 self-stretch items-center justify-center bg-[#F4F4F4] rounded-full cursor-pointer hover:bg-[#d8d8d8]"
        onClick={() => setAddModalOpen(true)}
      >
        <AddSVG />
        <p className="text-sm font-medium text-[#22234A]">Add new reference</p>
      </div>
      <ReferenceAddDialog
        profileId={profileId}
        isOpen={addModalOpen}
        setOpenStateFn={setAddModalOpen}
      />
    </div>
  );
};

const ReferenceAddDialog = ({
  profileId,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [referenceName, setReferenceName] = useState("");
  const [email, setEmail] = useState("");
  const [relationship, setRelationship] = useState<ReferenceRelationship>(
    referenceRelationshipList[0],
  );
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  useEffect(() => {
    setReferenceName("");
    setEmail("");
    setRelationship(referenceRelationshipList[0]);
    setCompany("");
    setTitle("");
    setMessage("");
    setShowWarning(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const submitDisabled =
    !referenceName || !email || !relationship || !company || !title || !message;

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    if (submitDisabled) {
      setShowWarning(true);
      return;
    }

    const data = {
      referenceName,
      email,
      relationship,
      company,
      title,
      message,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(addCandidateReference({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Add new reference
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Reference Name"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Input
                  type="text"
                  placeholder="John Smith"
                  value={referenceName}
                  onChange={(_evt, { value }) => setReferenceName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Reference Email"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Input
                  type="email"
                  placeholder="john.smith@gmail.com"
                  value={email}
                  onChange={(_evt, { value }) => setEmail(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="What is their relationship to you?"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Dropdown
                  value={relationship}
                  onOptionSelect={(_evt, data) =>
                    setRelationship(
                      data.selectedOptions[0] as ReferenceRelationship,
                    )
                  }
                >
                  {referenceRelationshipList.map((relationship) => (
                    <FluentOption key={`relationship-${relationship}`}>
                      {relationship}
                    </FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="At what company did you work together?"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Input
                  type="text"
                  placeholder="Microsoft"
                  value={company}
                  onChange={(_evt, { value }) => setCompany(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="What was your job title?"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Input
                  type="text"
                  placeholder="CEO"
                  value={title}
                  onChange={(_evt, { value }) => setTitle(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Please include a personalized message"
                className={fieldStyle.editField}
                validationMessage={
                  showWarning ? "This field is required" : null
                }
                required
              >
                <Textarea
                  className={inputStyle.largeTextarea}
                  value={message}
                  placeholder="Hi John, I've joined a network of fractional executives called Venturous and I've put you down as a reference..."
                  onChange={(_evt, { value }) => setMessage(value)}
                  resize="vertical"
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className={`h-10 py-3 px-6 rounded-full flex-1 ${submitDisabled ? "bg-[#E0E0E0] cursor-not-allowed" : "bg-[#19A594] cursor-pointer"}`}
                onClick={() => (submitDisabled ? null : onSubmit())}
              >
                <p className="text-sm font-medium text-white text-center">
                  Submit Request
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Projects = ({
  profileId,
  projects,
}: {
  profileId: string;
  projects?: {
    id?: string;
    title?: string;
    summary?: string;
    link?: string;
    imageLink?: string;
  }[];
}) => {
  const [editModalId, setEditModalId] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);

  const selectedProject = projects?.find(({ id }) => id === editModalId);

  return (
    <div className="card gap-3 w-full">
      <CardEditHeader text="Project" />
      {projects?.map((project, idx) => (
        <Project
          key={`project-${project.id ?? idx}`}
          openEditFn={() => setEditModalId(project.id!)}
          {...project}
        />
      ))}
      <div
        className="flex gap-2 py-2 px-4 self-stretch items-center justify-center bg-[#F4F4F4] rounded-full cursor-pointer hover:bg-[#d8d8d8]"
        onClick={() => setAddModalOpen(true)}
      >
        <AddSVG />
        <p className="text-sm font-medium text-[#22234A]">Add new project</p>
      </div>
      <ProjectAddOrEditDialog
        profileId={profileId}
        mode="add"
        isOpen={addModalOpen}
        setOpenStateFn={setAddModalOpen}
      />
      <ProjectAddOrEditDialog
        profileId={profileId}
        mode="edit"
        projectId={editModalId}
        {...selectedProject}
        isOpen={Boolean(editModalId)}
        setOpenStateFn={(open) => (!open ? setEditModalId("") : undefined)}
      />
    </div>
  );
};

const ProjectAddOrEditDialog = ({
  profileId,
  mode,
  projectId,
  title,
  summary,
  link,
  imageLink,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  mode: "add" | "edit";
  projectId?: string;
  title?: string;
  summary?: string;
  link?: string;
  imageLink?: string;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newTitle, setNewTitle] = useState("");
  const [newSummary, setNewSummary] = useState("");
  const [newLink, setNewLink] = useState("");
  const [newImage, setNewImage] = useState<File>();

  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  useEffect(() => {
    setNewTitle(title ?? "");
    setNewSummary(summary ?? "");
    setNewLink(link ?? "");
    setNewImage(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = new FormData();
    data.append("title", newTitle);
    data.append("summary", newSummary);
    data.append("link", newLink);

    if (newImage) {
      data.append("image", newImage);
    }

    if (mode === "add") {
      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(addCandidateProject({ id: profileId, data, token }));
      })();
    } else {
      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(updateCandidateProject({ id: projectId!, data, token }));
      })();
    }
  };

  const onDelete = () => {
    if (postingStatus !== "idle") {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(deleteCandidateProject({ id: projectId!, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              {mode === "add" ? "Add new project" : "Edit project"}
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Title" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="Case Study Title"
                  value={newTitle}
                  onChange={(_evt, { value }) => setNewTitle(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Summary" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="Summary"
                  value={newSummary}
                  onChange={(_evt, { value }) => setNewSummary(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Link" className={fieldStyle.editField}>
                <Input
                  type="url"
                  placeholder="www.mywebsite.com/case_study"
                  value={newLink}
                  onChange={(_evt, { value }) => setNewLink(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Cover Picture" className={fieldStyle.editField}>
                <div
                  className="flex w-[100px] h-[100px] self-center justify-center items-center border-slate border-solid border-2"
                  style={{
                    backgroundImage: `url(${newImage ? URL.createObjectURL(newImage) : imageLink})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <FileUploader
                  classes="flex-1 !min-w-[200px]"
                  multiple={false}
                  handleChange={(file?: File) => setNewImage(file)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              {mode === "edit" ? (
                <div
                  className="h-10 py-3 px-6 rounded-full bg-[#ED5C38] flex-1 cursor-pointer"
                  onClick={onDelete}
                >
                  <p className="text-sm font-medium text-white text-center">
                    Delete
                  </p>
                </div>
              ) : null}
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Experience = ({
  profileId,
  experiences,
  loading,
}: {
  profileId: string;
  experiences: IExperience[];
  loading: boolean;
}) => {
  const [selectedExperienceId, setSelectedExperienceId] = useState("");
  const [hideSelected, setHideSelected] = useState(false);
  const [experienceFilter, setExperienceFilter] = useState("All");
  const [editModalId, setEditModalId] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const selectedExperience = experiences.find(
    ({ id }) => id === selectedExperienceId,
  );

  const filteredExperiences =
    experienceFilter === "All"
      ? experiences
      : experiences.filter(
          ({ engagementType }) => engagementType === experienceFilter,
        );

  useEffect(() => {
    // If the selected experience is not one in the filtered list
    // Either because the filter has changed or no selection was made
    // Then set the first experience as the selected one
    if (!filteredExperiences.some(({ id }) => id === selectedExperienceId)) {
      setSelectedExperienceId(filteredExperiences[0]?.id ?? "");
      setHideSelected(true);
    }
  }, [experiences, filteredExperiences, selectedExperienceId]);

  const optionsSet: Set<string> = new Set<string>(["All"]);
  experiences.forEach(
    ({ engagementType }) => engagementType && optionsSet.add(engagementType),
  );

  if (loading) {
    return (
      <div className="card justify-start max-h-[calc(95vh-73px)] w-full overflow-hidden">
        <div className="flex pb-4 gap-2 items-center self-stretch justify-between border-bottom-line">
          <div className="flex gap-2 items-center">
            <p className="text-lg font-semibold tracking-[-0.18px]">
              Experiences
            </p>
          </div>
        </div>
        <div className="flex-1 flex items-start self-stretch overflow-hidden">
          <div
            className={`flex flex-col w-[300px] py-4 pr-2 flex-grow md:flex-grow-0 items-start self-stretch bg-white md:border-right-line gap-3 overflow-auto ${!hideSelected ? "hidden md:flex" : ""}`}
          >
            <SkeletonItem style={{ height: "80px", width: "100%" }} />
            <SkeletonItem style={{ height: "80px", width: "100%" }} />
            <SkeletonItem style={{ height: "80px", width: "100%" }} />
          </div>
          <div className="flex flex-col py-4 pl-5 items-start gap-5 flex-1 h-full w-full">
            <SkeletonItem style={{ height: "100%", width: "100%" }} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card justify-start max-h-[calc(95vh-73px)] w-full overflow-hidden">
      <div className="flex pb-4 gap-2 items-center self-stretch justify-between border-bottom-line">
        <div className="flex gap-2 items-center">
          <p className="text-lg font-semibold tracking-[-0.18px]">
            Experiences
          </p>
          {optionsSet.size > 2 ? (
            <Dropdown
              className="w-full md:w-auto"
              appearance="filled-darker"
              value={experienceFilter}
              onOptionSelect={(_evt, data) =>
                setExperienceFilter(data.selectedOptions[0]!)
              }
              style={{
                minWidth: "max-content",
                borderRadius: "999px",
              }}
            >
              {[...optionsSet].map((option) => (
                <FluentOption key={`experience-${option}`}>
                  {option}
                </FluentOption>
              ))}
            </Dropdown>
          ) : null}
        </div>
        <div
          className="flex gap-2 py-2 px-4 self-stretch items-center justify-center bg-[#ED5C381F] hover:bg-[#ED5C383F] rounded-full cursor-pointer"
          onClick={() => setAddModalOpen(true)}
        >
          <AddSVG stroke="#ED5C38" height="16" width="16" />
          <p className="text-sm font-medium text-[#ED5C38]">Add experience</p>
        </div>
      </div>
      <div className="flex-1 flex items-start self-stretch overflow-hidden">
        <div
          className={`flex flex-col w-[300px] py-4 pr-2 flex-grow md:flex-grow-0 items-start self-stretch bg-white md:border-right-line gap-3 overflow-auto ${!hideSelected ? "hidden md:flex" : ""}`}
        >
          {filteredExperiences.map(
            ({
              id,
              companyName,
              companyLogoLink,
              jobTitle,
              startDate,
              endDate,
              engagementType,
            }) => {
              const startString = startDate
                ? dateStringToLocalDate(startDate)?.toLocaleDateString(
                    "en-US",
                    { month: "short", year: "numeric" },
                  ) ?? "Unknown"
                : "Unknown";
              const endString = endDate
                ? dateStringToLocalDate(endDate)?.toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                  }) ?? "Unknown"
                : "Present";
              const isSelected = id === selectedExperienceId;

              return (
                <div
                  key={`experience-${id}`}
                  className={`flex flex-col p-3 items-start self-stretch gap-3 rounded-xl cursor-pointer bg-white ${isSelected ? "md:bg-[#F3F5F7]" : ""} hover:bg-[#D1D9E1]`}
                  onClick={() => {
                    id
                      ? (setSelectedExperienceId(id), setHideSelected(false))
                      : null;
                  }}
                >
                  <div className="flex items-center self-stretch gap-3">
                    <Avatar
                      image={{ src: companyLogoLink }}
                      name={companyName}
                      size={48}
                    />
                    <div className="flex flex-col items-start gap-1">
                      <p className="text-sm font-semibold text-[#22234A]">{`${jobTitle} at ${companyName}`}</p>
                      <p className="text-sm text-[#727E94]">{`${startString} - ${endString} (${engagementType})`}</p>
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
        <ExperienceEntry
          experience={selectedExperience}
          hideOnMobile={hideSelected}
          closeOnMobileFn={() => setHideSelected(true)}
          editFn={() => setEditModalId(selectedExperienceId)}
        />
      </div>
      <ExperienceAddOrEditDialog
        profileId={profileId}
        mode="add"
        isOpen={addModalOpen}
        setOpenStateFn={setAddModalOpen}
      />
      <ExperienceAddOrEditDialog
        profileId={profileId}
        mode="edit"
        experienceId={editModalId}
        {...selectedExperience}
        isOpen={Boolean(editModalId)}
        setOpenStateFn={(open) => (!open ? setEditModalId("") : undefined)}
      />
    </div>
  );
};

const ExperienceEntry = ({
  experience,
  hideOnMobile,
  closeOnMobileFn,
  editFn,
}: {
  experience?: IExperience;
  hideOnMobile: boolean;
  closeOnMobileFn: () => void;
  editFn: () => void;
}) => {
  if (!experience) {
    return <div className="flex flex-col py-4 pl-5 items-start gap-5 flex-1" />;
  }

  const selectedStartDateString = experience.startDate
    ? dateStringToLocalDate(experience.startDate)?.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }) ?? "Unknown"
    : "Unknown";
  const selectedEndDateString = experience.endDate
    ? dateStringToLocalDate(experience.endDate)?.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }) ?? "Unknown"
    : "Present";

  return (
    <div
      className={`${hideOnMobile ? "hidden md:flex" : "flex"} flex-col py-4 pl-5 self-stretch items-start gap-5 flex-1 overflow-auto`}
    >
      <div
        className="md:hidden min-h-7 min-w-7 cursor-pointer p-[2px] rounded-md hover:bg-[#d8d8d8]"
        onClick={closeOnMobileFn}
      >
        <ArrowLeftSVG />
      </div>
      <div className="flex items-center self-stretch gap-3">
        <Avatar
          image={{ src: experience.companyLogoLink }}
          name={experience.companyName}
          size={48}
        />
        <div className="flex flex-col gap-1 items-start self-stretch flex-1">
          <div className="flex self-stretch items-center justify-between">
            <p className="text-base font-semibold text-[#22234A]">{`${experience?.jobTitle} at ${experience?.companyName}`}</p>
            <p className="edit-text" onClick={editFn}>
              Edit
            </p>
          </div>
          <p className="text-sm font-medium text-[#727E94]">{`${selectedStartDateString} - ${selectedEndDateString} (${experience?.engagementType})`}</p>
          {experience.location ? (
            <div className="flex gap-1 items-center">
              <LocationSVG />
              <p className="text-sm font-medium text-[#4E4F6C]">
                {experience.location}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {experience.functions?.length ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            {experience.functions.length === 1 ? "Function" : "Functions"}
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.functions.join(", ")}
          </p>
        </div>
      ) : null}
      {experience.companyDescription ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            Company Overview
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.companyDescription}
          </p>
        </div>
      ) : null}
      {experience.industries &&
      (experience.industries !== "Other" || experience.otherIndustries) ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">Industry</p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.industries && experience.industries !== "Other"
              ? experience.industries
              : experience.otherIndustries}
          </p>
        </div>
      ) : null}
      {experience.stage ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            Stage when they joined
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.stage}
          </p>
        </div>
      ) : null}
      {experience.outcomes ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            Responsibilities and Outcomes
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.outcomes}
          </p>
        </div>
      ) : null}
    </div>
  );
};

const ExperienceAddOrEditDialog = ({
  profileId,
  mode,
  experienceId,
  companyName,
  companyLogoLink,
  companyDescription,
  industries,
  otherIndustries,
  stage,
  jobTitle,
  engagementType,
  functions,
  location,
  startDate,
  endDate,
  outcomes,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  mode: "add" | "edit";
  experienceId?: string;
  companyName?: string | undefined;
  companyLogoLink?: string | undefined;
  companyDescription?: string | undefined;
  industries?: Industry | "" | undefined;
  otherIndustries?: string;
  stage?: Stage | "" | undefined;
  jobTitle?: string | undefined;
  engagementType?: EngagementType;
  functions?: FunctionArea[];
  location?: string;
  startDate?: string | undefined;
  endDate?: string | undefined;
  outcomes?: string | undefined;
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newCompanyDescription, setNewCompanyDescription] = useState("");
  const [newIndustries, setNewIndustries] = useState<Industry | "">("");
  const [newOtherIndustries, setNewOtherIndustries] = useState("");
  const [newStage, setNewStage] = useState<Stage | "">("");
  const [newJobTitle, setNewJobTitle] = useState("");
  const [newEngagementType, setNewEngagementType] =
    useState<EngagementType>("Part-Time");
  const [newFunctions, setNewFunctions] = useState<FunctionArea[]>([]);
  const [newLocation, setNewLocation] = useState("");
  const [newStartDate, setNewStartDate] = useState<Date | null>(null);
  const [newEndDate, setNewEndDate] = useState<Date | null>(null);
  const [newOutcomes, setNewOutcomes] = useState("");
  const [newCompanyLogo, setNewCompanyLogo] = useState<File>();

  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  useEffect(() => {
    setNewCompanyName(companyName ?? "");
    setNewCompanyDescription(companyDescription ?? "");
    setNewIndustries(industries ?? "");
    setNewOtherIndustries(otherIndustries ?? "");
    setNewStage(stage ?? "");
    setNewJobTitle(jobTitle ?? "");
    setNewEngagementType(engagementType ?? "Part-Time");
    setNewFunctions(functions ? [...functions] : []);
    setNewLocation(location ?? "");
    setNewStartDate(startDate ? dateStringToLocalDate(startDate) : null);
    setNewEndDate(endDate ? dateStringToLocalDate(endDate) : null);
    setNewOutcomes(outcomes ?? "");
    setNewCompanyLogo(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = new FormData();
    data.append("companyName", newCompanyName);
    data.append("companyDescription", newCompanyDescription);
    data.append("industries", newIndustries);
    data.append("otherIndustries", newOtherIndustries);
    data.append("stage", newStage);
    data.append("jobTitle", newJobTitle);
    data.append("engagementType", newEngagementType);
    newFunctions.forEach((func) => data.append("functions[]", func));
    data.append("location", newLocation);
    data.append(
      "startDate",
      newStartDate ? localDateToDateString(newStartDate) : "",
    );
    data.append("endDate", newEndDate ? localDateToDateString(newEndDate) : "");
    data.append("outcomes", newOutcomes);

    if (newCompanyLogo) {
      data.append("companyLogo", newCompanyLogo);
    }

    if (mode === "add") {
      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(addCandidateExperience({ id: profileId, data, token }));
      })();
    } else {
      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(
          updateCandidateExperience({ id: experienceId!, data, token }),
        );
      })();
    }
  };

  const onDelete = () => {
    if (postingStatus !== "idle") {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(deleteCandidateExperience({ id: experienceId!, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              {mode === "add" ? "Add new experience" : "Edit experience"}
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Position" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="CEO"
                  value={newJobTitle}
                  onChange={(_evt, { value }) => setNewJobTitle(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Company" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="Microsoft"
                  value={newCompanyName}
                  onChange={(_evt, { value }) => setNewCompanyName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Company Logo" className={fieldStyle.editField}>
                <div
                  className="flex w-[100px] h-[100px] self-center justify-center items-center border-slate border-solid border-2"
                  style={{
                    backgroundImage: `url(${newCompanyLogo ? URL.createObjectURL(newCompanyLogo) : companyLogoLink})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <FileUploader
                  classes="flex-1 !min-w-[200px]"
                  multiple={false}
                  handleChange={(file?: File) => setNewCompanyLogo(file)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Engagement Type" className={fieldStyle.editField}>
                <Dropdown
                  value={newEngagementType}
                  onOptionSelect={(_evt, data) =>
                    setNewEngagementType(
                      data.selectedOptions[0] as EngagementType,
                    )
                  }
                >
                  <FluentOption>Part-Time</FluentOption>
                  <FluentOption>Full-Time</FluentOption>
                  <FluentOption>Consultant</FluentOption>
                  <FluentOption>Advisor</FluentOption>
                  <FluentOption>Board</FluentOption>
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Function" className={fieldStyle.editField}>
                <Dropdown
                  value={newFunctions.join(", ")}
                  selectedOptions={newFunctions}
                  onOptionSelect={(_evt, data) => {
                    if (
                      data.selectedOptions.length !== newFunctions.length ||
                      newFunctions.some(
                        (option) => !data.selectedOptions.includes(option),
                      )
                    ) {
                      setNewFunctions(data.selectedOptions as FunctionArea[]);
                    }
                  }}
                >
                  {functionAreaList.map((area) => (
                    <FluentOption key={`function-${area}`}>{area}</FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Location" className={fieldStyle.editField}>
                <Input
                  type="text"
                  placeholder="New York, NY"
                  value={newLocation}
                  onChange={(_evt, { value }) => setNewLocation(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Date"
                hint="Leave the second date blank if this engagement is ongoing"
                className={fieldStyle.editField}
              >
                <div className="flex flex-row gap-2 justify-between">
                  <ReactDatePicker
                    className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
                    selected={newStartDate}
                    onChange={(date) => setNewStartDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showIcon
                    icon={<CalendarSVG className="top-[2px]" />}
                  />
                  <p className="m-auto">&mdash;</p>
                  <ReactDatePicker
                    className="flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
                    selected={newEndDate}
                    onChange={(date) => setNewEndDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showIcon
                    icon={<CalendarSVG className="top-[2px]" />}
                  />
                </div>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Responsibilities and Outcomes"
                className={fieldStyle.editField}
              >
                <Textarea
                  className={inputStyle.largeTextarea}
                  value={newOutcomes}
                  onChange={(_evt, { value }) => setNewOutcomes(value)}
                  resize="vertical"
                />
              </Field>
            </div>
            <div className="w-full h-[1px] min-h-[1px] bg-[#F2F2F2]" />
            <p className="text-sm font-semibold text-[#22234A]">
              More about your role & company
            </p>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="What does the company do?"
                className={fieldStyle.editField}
              >
                <Textarea
                  placeholder="Describe it in one or two sentences"
                  value={newCompanyDescription}
                  onChange={(_evt, data) =>
                    setNewCompanyDescription(data.value)
                  }
                  resize="vertical"
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="What industry does the company operate in?"
                className={fieldStyle.editField}
              >
                <InfoLabel
                  className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
                  size="small"
                  info={
                    <p>
                      If the company operates in multiple industries, choose the
                      primary one.
                    </p>
                  }
                >
                  Choose 1 industry from the list
                </InfoLabel>
                <Dropdown
                  value={newIndustries}
                  selectedOptions={[newIndustries]}
                  onOptionSelect={(_evt, data) => {
                    const option = data.optionValue as Industry;
                    setNewIndustries(option);

                    if (option !== "Other") {
                      setNewOtherIndustries("");
                    }
                  }}
                >
                  {industryList.map((option) => (
                    <FluentOption key={`industry-${option}`}>
                      {option}
                    </FluentOption>
                  ))}
                </Dropdown>
                {newIndustries === "Other" ? (
                  <Input
                    type="text"
                    placeholder="Other industry"
                    value={newOtherIndustries}
                    onChange={(_evt, { value }) => setNewOtherIndustries(value)}
                  />
                ) : null}
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="What stage was the company at when you hired?"
                className={fieldStyle.editField}
              >
                <Dropdown
                  value={newStage}
                  selectedOptions={[newStage]}
                  onOptionSelect={(_evt, data) =>
                    setNewStage(data.optionValue as Stage)
                  }
                >
                  {stageList.map((stage) => (
                    <FluentOption key={`stage-${stage}`}>{stage}</FluentOption>
                  ))}
                </Dropdown>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              {mode === "edit" ? (
                <div
                  className="h-10 py-3 px-6 rounded-full bg-[#ED5C38] flex-1 cursor-pointer"
                  onClick={onDelete}
                >
                  <p className="text-sm font-medium text-white text-center">
                    Delete
                  </p>
                </div>
              ) : null}
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Questionnaires = ({
  profileId,
  businessPrinciples,
  teams,
  separatesFromPeers,
  peerDescription,
  communicationSkills,
  loading,
}: {
  profileId: string;
  businessPrinciples: BusinessPrinciple[];
  teams?: string;
  separatesFromPeers?: string;
  peerDescription: PeerDescription[];
  communicationSkills: CommunicationSkill[];
  loading: boolean;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (loading) {
    return (
      <div className="card gap-4 items-stretch w-full">
        <CardEditHeader text="Questionnaires" />
        <QuestionnaireEntry
          question="Rank the top business principles that drive you"
          details="Place the tag sequentially"
          loading
        />
        <QuestionnaireEntry
          question="How would your peers describe you?"
          rankedList={peerDescription}
          loading
        />
        <QuestionnaireEntry
          question="Communication Skills"
          rankedList={communicationSkills}
          loading
        />
      </div>
    );
  }

  return (
    <div className="card gap-4 items-stretch w-full">
      <CardEditHeader
        text="Questionnaires"
        onClick={() => setEditModalOpen(true)}
      />
      <QuestionnaireEntry
        question="Rank the top business principles that drive you"
        details="Place the tag sequentially"
        rankedList={businessPrinciples}
      />
      {/* <QuestionnaireEntry
        question="Discuss your experience in leading, building, and growing a team"
        details="(150 words or less)"
        answer={teams}
      /> */}
      {/* <QuestionnaireEntry
        question="What separates you from your peers?"
        details="(150 words or less)"
        answer={separatesFromPeers}
      /> */}
      <QuestionnaireEntry
        question="How would your peers describe you?"
        rankedList={peerDescription}
      />
      <QuestionnaireEntry
        question="Communication Skills"
        rankedList={communicationSkills}
      />
      <QuestionnairesEditDialog
        profileId={profileId}
        businessPrinciples={businessPrinciples}
        teams={teams}
        separatesFromPeers={separatesFromPeers}
        peerDescription={peerDescription}
        communicationSkills={communicationSkills}
        isOpen={editModalOpen}
        setOpenStateFn={setEditModalOpen}
      />
    </div>
  );
};

const SortableItem = (props: { key: string; id: string; idx: number }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex items-center"
    >
      <p>
        {props.idx + 1}. {props.id}
      </p>
    </div>
  );
};

const QuestionnairesEditDialog = ({
  profileId,
  businessPrinciples,
  // teams,
  // separatesFromPeers,
  peerDescription,
  communicationSkills,
  isOpen,
  setOpenStateFn,
}: {
  profileId: string;
  businessPrinciples: BusinessPrinciple[];
  teams?: string;
  separatesFromPeers?: string;
  peerDescription: PeerDescription[];
  communicationSkills: CommunicationSkill[];
  isOpen: boolean;
  setOpenStateFn: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newBusinessPrinciples, setNewBusinessPrinciples] = useState<
    BusinessPrinciple[]
  >([]);
  const [newPeerDescription, setNewPeerDescription] = useState<
    PeerDescription[]
  >([]);
  const [newCommunicationSkills, setNewCommunicationSkills] = useState<
    CommunicationSkill[]
  >([]);

  const emails = useAppSelector(
    (state) => state.candidateProfile.candidate.emails,
  );
  const postingStatus = useAppSelector(
    (state) => state.candidateProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = <T,>(
    event: DragEndEvent,
    list: T[],
    setterFn: React.Dispatch<React.SetStateAction<T[]>>,
  ): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = list.indexOf(active.id as T);
      const newIndex = list.indexOf(over.id as T);

      setterFn(arrayMove(list, oldIndex, newIndex));
    }
  };

  useEffect(() => {
    setNewBusinessPrinciples(
      businessPrinciples.length
        ? businessPrinciples
        : [...businessPrincipleList],
    );
    setNewPeerDescription(
      peerDescription.length ? peerDescription : [...peerDescriptionList],
    );
    setNewCommunicationSkills(
      communicationSkills.length
        ? communicationSkills
        : [...communicationSkillList],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setOpenStateFn(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (postingStatus !== "idle") {
      return;
    }

    const data = {
      emails,
      questionnaireBusinessPrinciples: newBusinessPrinciples,
      questionnairePeerDescription: newPeerDescription,
      questionnaireCommunicationSkills: newCommunicationSkills,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(updateCandidateProfile({ id: profileId, data, token }));
    })();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_evt, data) => setOpenStateFn(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Questionnaire
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Rank the top business principles that drive you"
                className={fieldStyle.editField}
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) =>
                    handleDragEnd(
                      event,
                      newBusinessPrinciples,
                      setNewBusinessPrinciples,
                    )
                  }
                >
                  <SortableContext
                    items={newBusinessPrinciples}
                    strategy={verticalListSortingStrategy}
                  >
                    {newBusinessPrinciples.map((id, idx) => (
                      <SortableItem key={id} id={id} idx={idx} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="How would your peers describe you?"
                className={fieldStyle.editField}
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) =>
                    handleDragEnd(
                      event,
                      newPeerDescription,
                      setNewPeerDescription,
                    )
                  }
                >
                  <SortableContext
                    items={newPeerDescription}
                    strategy={verticalListSortingStrategy}
                  >
                    {newPeerDescription.map((id, idx) => (
                      <SortableItem key={id} id={id} idx={idx} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Stack rank from 1-3, 1 being your best skill to 3 your least"
                className={fieldStyle.editField}
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) =>
                    handleDragEnd(
                      event,
                      newCommunicationSkills,
                      setNewCommunicationSkills,
                    )
                  }
                >
                  <SortableContext
                    items={newCommunicationSkills}
                    strategy={verticalListSortingStrategy}
                  >
                    {newCommunicationSkills.map((id, idx) => (
                      <SortableItem key={id} id={id} idx={idx} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const QuestionnaireEntry = ({
  question,
  details,
  answer,
  rankedList,
  loading,
}: {
  question: string;
  details?: string;
  answer?: string;
  rankedList?: string[];
  loading?: boolean;
}) => {
  return (
    <div className="card p-3 gap-3 bg-[#003c320d]">
      <div className="flex flex-col gap-[2px] self-stretch items-start">
        <p className="text-sm font-semibold text-[#22234A]">{question}</p>
        <p className="text-sm font-medium text-[#4E4F6C]">{details}</p>
      </div>
      <div className="flex gap-[10px] p-3 self-stretch items-start bg-white rounded-xl">
        {loading ? (
          <Skeleton style={{ height: "80px", width: "100%" }}>
            <SkeletonItem style={{ height: "100%", width: "100%" }} />
          </Skeleton>
        ) : answer ? (
          <p className="text-sm font-medium text-[#4E4F6C]">{answer}</p>
        ) : (
          <div className="flex flex-col gap-1 items-start self-stretch">
            <ol className="list-[auto] pl-5">
              {rankedList?.map((skill, idx) => (
                <li
                  key={`skill-${idx}`}
                  className="text-sm font-medium text-[#22234A]"
                >
                  {skill}
                </li>
              ))}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

const CardEditHeader = ({
  text,
  isInternal,
  onClick,
}: {
  text: string;
  isInternal?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className="flex self-stretch items-center justify-between pb-4 border-bottom-line">
      <div className="flex gap-4 self-stretch items-center">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          {text}
        </p>
        {isInternal ? (
          <InfoLabel
            className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
            size="large"
            info={
              <p>
                This information is used by Venturous to identify engagements
                you might be interested in. It is never shared with companies.
              </p>
            }
          >
            Private
          </InfoLabel>
        ) : null}
      </div>
      {onClick ? (
        <p className="edit-text" onClick={onClick}>
          Edit
        </p>
      ) : null}
    </div>
  );
};

const ContentRow = ({
  Icon,
  text,
  response,
  responseIcon,
  isLast,
  loading,
}: {
  Icon?: JSX.Element;
  text: string;
  response?: string;
  responseIcon?: JSX.Element;
  isLast?: boolean;
  loading?: boolean;
}) => {
  return (
    <div
      className={`flex gap-1 self-stretch items-center justify-between min-h-12 py-4 ${isLast ? "" : "border-bottom-line"}`}
    >
      <div className="flex gap-3 items-center">
        {Icon}
        <p className="text-sm font-semibold text-[#22234A]">{text}</p>
      </div>
      {loading ? (
        response ? (
          <Skeleton style={{ height: "20px", width: "30%" }}>
            <SkeletonItem style={{ height: "100%", width: "100%" }} />
          </Skeleton>
        ) : (
          <Skeleton style={{ height: "24px", width: "24px" }}>
            <SkeletonItem
              shape="circle"
              style={{ height: "100%", width: "100%" }}
            />
          </Skeleton>
        )
      ) : (
        <>
          <p className="max-w-[70%] text-sm font-medium text-[#22234A] text-nowrap overflow-hidden overflow-ellipsis hover:overflow-visible hover:text-wrap">
            {response}
          </p>
          {responseIcon}
        </>
      )}
    </div>
  );
};

export default CandidateProfileEdit;
