import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  ArrowExitRegular,
  BuildingPeopleRegular,
  EditRegular,
  PeopleSearchRegular,
  PeopleTeamRegular,
} from "@fluentui/react-icons";
import { MenuSvg, VenturousSVG } from "../assets/svgs";
import { useMenuStyles } from "../fluentStyles";

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <nav
      className={`${pathname === "/onboarding" ? "hidden" : "flex"} sticky top-0 z-10 justify-between items-center border-bottom-line bg-white`}
    >
      <div className="h-[72px] py-4 px-[40px] flex gap-1 items-center">
        <VenturousSVG />
        <p className="font-logo text-[37.894px] tracking-[-1.894px] text-[#19A594] cursor-default">
          ENTUROUS
        </p>
      </div>
      <Links />
      <NotificationsAndLinks />
    </nav>
  );
};

const Links = () => {
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const userType = user?.userType;
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isCandidate =
    userType?.includes("Admin") || userType?.includes("Candidate");
  const isCompany =
    userType?.includes("Admin") || userType?.includes("Company");
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  const links = [];
  if (isCandidate) {
    links.push(
      <Button
        key="navigation-candidate-edit"
        appearance="subtle"
        icon={<EditRegular />}
        onClick={() => navigate("/profile/edit")}
      >
        Profile
      </Button>,
    );
  }

  if (isCompany) {
    links.push(
      <Button
        key="navigation-company-edit"
        appearance="subtle"
        icon={<BuildingPeopleRegular />}
        onClick={() => navigate("/company/profile/edit")}
      >
        Company
      </Button>,
      <Button
        key="navigation-opportunities"
        appearance="subtle"
        icon={<PeopleSearchRegular />}
        onClick={() => navigate("/opening")}
      >
        Opportunities
      </Button>,
      <Button
        key="navigation-team"
        appearance="subtle"
        icon={<PeopleTeamRegular />}
        onClick={() => navigate("/team")}
      >
        Team
      </Button>,
    );
  }

  return (
    <div className="flex-1 hidden lg:flex gap-3 justify-center">{links}</div>
  );
};

const NotificationsAndLinks = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const user = useAppSelector((state) => state.auth.user);
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuStyles = useMenuStyles();

  const userImage = user?.profilePictureLink;
  const userName = user?.name;
  const userType = user?.userType;

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  const handleLogout = () => {
    void logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isCandidate =
    userType?.includes("Admin") || userType?.includes("Candidate");
  const isCompany =
    userType?.includes("Admin") || userType?.includes("Company");
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  // If not logged in
  if (!isAuthenticated && !isLoading) {
    // Display nothing if on a claim page
    if (pathname.startsWith("/claim") || pathname.endsWith("/claim")) {
      return;
    }

    // Display the login and sign up buttons otherwise
    return (
      <div className="pr-10 hidden md:flex flex-row gap-3 items-center justify-end ">
        <Button appearance="primary" size="medium" onClick={handleLogin}>
          Log In
        </Button>
        <Button appearance="secondary" size="medium" onClick={handleSignUp}>
          Sign Up
        </Button>
      </div>
    );
  }

  const menuButtons: JSX.Element[] = [];
  if (isCandidate) {
    menuButtons.push(
      <MenuItem
        key="menu-candidate-edit"
        icon={<EditRegular />}
        onClick={() => navigate("/profile/edit")}
      >
        Profile
      </MenuItem>,
    );
  }
  if (isCompany) {
    menuButtons.push(
      <MenuItem
        key="menu-company-edit"
        icon={<BuildingPeopleRegular />}
        onClick={() => navigate("/company/profile/edit")}
      >
        Company
      </MenuItem>,
      <MenuItem
        key="menu-opportunities"
        icon={<PeopleSearchRegular />}
        onClick={() => navigate("/opening")}
      >
        Opportunities
      </MenuItem>,
      <MenuItem
        key="menu-team"
        icon={<PeopleTeamRegular />}
        onClick={() => navigate("/team")}
      >
        Team
      </MenuItem>,
    );
  }
  menuButtons.push(
    <MenuItem
      key="menu-log-out"
      icon={<ArrowExitRegular />}
      onClick={handleLogout}
    >
      Log Out
    </MenuItem>,
  );

  return (
    <>
      <div className="flex-[0_1_268px] hidden lg:block text-end">
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <Avatar
              className={menuStyles.userButton}
              image={{ src: userImage }}
              name={userName ?? "User"}
              color="brand"
              size={32}
            />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>{menuButtons}</MenuList>
          </MenuPopover>
        </Menu>
      </div>
      <div className="lg:hidden">
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <div className="mr-10">
              <MenuSvg />
            </div>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>{menuButtons}</MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </>
  );
};

export default Navigation;
