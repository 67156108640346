const userTypeList = ["Admin", "Candidate", "Company"] as const;
export type UserType = (typeof userTypeList)[number];

/* Job Opening */
export interface CompanyJobOpening {
  id?: string;
  companyRecordId?: string;
  role?: string;
  jobDescriptionLink?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  convertsToFullTime?: ConvertsToFullTime;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  noteForVenturous?: string;
  status?: JobOpeningStatus;
  candidates?: CandidateStatus[];
  interviews?: CompanyInterview[];
}

export type CreateOrUpdateCompanyJobOpening = Omit<
  CompanyJobOpening,
  "id" | "companyRecordId" | "status" | "candidates"
>;

export const convertsToFullTimeList = ["Yes", "No", "Unsure"] as const;
export type ConvertsToFullTime = (typeof convertsToFullTimeList)[number];

export const jobOpeningStatusList = [
  "New Opening",
  "Creating Job Spec",
  "Identifying Candidates",
  "Presenting Shortlist",
  "Role Filled (won)",
  "Role Filled (lost)",
] as const;
export type JobOpeningStatus = (typeof jobOpeningStatusList)[number];

/* Candidate Status */
export interface CandidateStatus {
  id: string;
  candidateId: string;
  name?: string;
  headline?: string;
  profilePictureLink?: string;
  status?: CandidateApplicationStatus;
  scope?: string;
  agreesWithScope?: boolean;
  budget?: string;
  agreesWithPay?: boolean;
  earliestStartDate?: string;
  agreesWithStartDate?: boolean;
  openToFullTime?: boolean;
}

export const candidateApplicationStatusList = [
  "Identified",
  "Checking Interest",
  "Screening",
  "Possible Fit",
  "Shortlisted",
  "Accepted by Client",
  "Rejected for Role",
  "Not Interested",
] as const;
export type CandidateApplicationStatus =
  (typeof candidateApplicationStatusList)[number];

/* Interview */
export interface CompanyInterview {
  id?: string;
  name?: string;
  duration?: number;
  participants?: string[];
  step?: number;
  interviewRequested?: string[];
  interviewCompleted?: string[];
}

/* SVG */
export type SvgJsxType = (props: {
  className?: string;
  height?: string;
  width?: string;
  fill?: string;
  stroke?: string;
  onClick?: () => void;
}) => JSX.Element;

/* Error Payload */
export interface ErrorPayload {
  error: ErrorType;
  message: string;
}

export type ErrorType =
  | "UNEXPECTED"
  | "UNAUTHORIZED"
  | "SERVER_ERROR"
  | "GENERIC_BAD_INPUT"
  | "SPECIFIC_BAD_INPUT";
