import { useEffect, useState } from "react";
import {
  NavigateFunction,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Skeleton,
  SkeletonItem,
  Avatar,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogTrigger,
  DialogContent,
  DialogActions,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { useDialogStyles, useMenuStyles } from "../fluentStyles";
import { NewOpportunityTile } from "../elements/OpportunityTile";
import { CloseCircleSVG } from "../assets/svgs";
import {
  acceptCandidate,
  completeInterview,
  getOpening,
  rejectCandidate,
  resetPostingStatus,
  scheduleInterview,
} from "../redux/features/openingSlice";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import ProgressTracker from "../elements/ProgressTracker";
import { CandidateStatus, CompanyJobOpening } from "../types";

const candidatePrompts = [
  "Request Interview",
  "Mark Interview Complete",
  "Make Offer",
  "Reject",
] as const;
type CandidatePrompt = (typeof candidatePrompts)[number];

const CompanyOpportunity = () => {
  const [dataRequested, setDataRequested] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const opening = useAppSelector((state) => state.opening.opening);
  const openingStatus = useAppSelector((state) => state.opening.status);
  const postingStatus = useAppSelector((state) => state.opening.postingStatus);

  const { openingId } = useParams();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    setDataRequested(false);
    setLoaded(false);
  }, [openingId]);

  useEffect(() => {
    if (openingId && !dataRequested) {
      const controller = new AbortController();

      void (async () => {
        const token = await getAccessTokenSilently();
        void dispatch(
          getOpening({
            id: openingId,
            getCandidates: true,
            token,
            signal: controller.signal,
          }),
        );
        setDataRequested(true);
      })();

      return () => {
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openingId]);

  useEffect(() => {
    if (!loaded && dataRequested && openingStatus === "succeeded") {
      setLoaded(true);
    }
  }, [loaded, dataRequested, openingStatus]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
    } else if (postingStatus === "failed") {
      // TODO: Explain error?
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  useEffect(() => {
    document.title = "View an opportunity";
  }, []);

  const requestInterview = (candidateId: string, interviewId: string) => {
    if (!openingId || posting) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        scheduleInterview({ openingId, candidateId, interviewId, token }),
      );
    })();
  };

  const finishInterview = (candidateId: string, interviewId: string) => {
    if (!openingId || posting) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        completeInterview({ openingId, candidateId, interviewId, token }),
      );
    })();
  };

  const makeOffer = (statusId: string) => {
    if (!openingId || posting) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        acceptCandidate({ openingId, candidateId: statusId, token }),
      );
    })();
  };

  const reject = (statusId: string) => {
    if (!openingId || posting) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        rejectCandidate({ openingId, candidateId: statusId, token }),
      );
    })();
  };

  return (
    <>
      <div className="flex flex-col gap-5">
        <Opportunity opening={opening} loaded={loaded} />
        <Candidates
          opening={opening}
          candidates={opening?.candidates}
          loaded={loaded}
          requestInterview={requestInterview}
          finishInterview={finishInterview}
          makeOffer={makeOffer}
          reject={reject}
        />
        <NewDialog loaded={loaded} />
      </div>
    </>
  );
};

const Opportunity = ({
  opening,
  loaded,
}: {
  opening?: CompanyJobOpening;
  loaded: boolean;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-[#22234A]">Opportunity</h2>
      </div>
      <NewOpportunityTile
        opening={loaded ? opening : undefined}
        view="opportunity"
      />
    </div>
  );
};

const Candidates = ({
  opening,
  candidates,
  loaded,
  requestInterview,
  finishInterview,
  makeOffer,
  reject,
}: {
  opening?: CompanyJobOpening;
  candidates?: CandidateStatus[];
  loaded: boolean;
  requestInterview: (candidateId: string, interviewId: string) => void;
  finishInterview: (candidateId: string, interviewId: string) => void;
  makeOffer: (statusId: string) => void;
  reject: (statusId: string) => void;
}) => {
  const [stages, setStages] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const progressStages = ["Presented"];
    opening?.interviews?.forEach((interview) => {
      progressStages.push(
        `${interview.name} Requested`,
        `${interview.name} Completed`,
      );
    });
    progressStages.push(`Offer Made`);

    setStages(progressStages);
  }, [opening]);

  const closed =
    opening?.status === "Role Filled (won)" ||
    opening?.status === "Role Filled (lost)";

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-[#22234A]">
          Shortlisted Candidates
        </h2>
      </div>
      {!loaded ? (
        <CandidateTile
          navigate={navigate}
          stages={stages}
          requestInterview={requestInterview}
          finishInterview={finishInterview}
          makeOffer={makeOffer}
          reject={reject}
        />
      ) : candidates?.length ? (
        candidates.map((candidate) => (
          <CandidateTile
            key={`opening-${candidate.id}`}
            opening={opening}
            candidate={candidate}
            stages={stages}
            closed={closed}
            navigate={navigate}
            requestInterview={requestInterview}
            finishInterview={finishInterview}
            makeOffer={makeOffer}
            reject={reject}
          />
        ))
      ) : (
        <div className="card flex-col gap-4 w-full">
          <p className="text-base font-medium text-[#727E94]">
            A shortlist is still being prepared
          </p>
        </div>
      )}
    </div>
  );
};

const CandidateTile = ({
  candidate,
  opening,
  stages,
  closed,
  navigate,
  requestInterview,
  finishInterview,
  makeOffer,
  reject,
}: {
  candidate?: CandidateStatus;
  opening?: CompanyJobOpening;
  stages: string[];
  closed?: boolean;
  navigate: NavigateFunction;
  requestInterview: (candidateId: string, interviewId: string) => void;
  finishInterview: (candidateId: string, interviewId: string) => void;
  makeOffer: (statusId: string) => void;
  reject: (statusId: string) => void;
}) => {
  const postingStatus = useAppSelector((state) => state.opening.postingStatus);
  const posting = postingStatus !== "idle";

  const menuStyle = useMenuStyles();

  if (!candidate) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
        </div>
        {/* <Skeleton className="w-full h-8">
          <SkeletonItem size={32} className="w-full" />
        </Skeleton> */}
      </div>
    );
  }

  const targetScope = opening?.scope;
  const targetStartDate = opening?.desiredStartDate;
  const targetStartDateString =
    targetStartDate &&
    (dateStringToLocalDate(targetStartDate)?.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }) ??
      "Unknown");
  const transitionsToFullTime = opening?.convertsToFullTime === "Yes";

  const prompts = new Set<CandidatePrompt>(["Reject", "Make Offer"]);
  let currentInterview: string | undefined;
  let stageIndex = 0;

  if (candidate.status === "Accepted by Client") {
    stageIndex = stages.length - 1;
  } else if (opening?.interviews) {
    for (const interview of opening.interviews) {
      if (interview.interviewCompleted?.includes(candidate.candidateId)) {
        stageIndex += 2;
      } else if (
        interview.interviewRequested?.includes(candidate.candidateId)
      ) {
        prompts.add("Mark Interview Complete");
        currentInterview = interview.id;
        stageIndex++;
        break;
      } else {
        prompts.add("Request Interview");
        currentInterview = interview.id;
        break;
      }
    }
  }

  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  if (
    closed ||
    candidate.status === "Rejected for Role" ||
    candidate.status === "Accepted by Client"
  ) {
    /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

    prompts.clear();
  }

  const actions: JSX.Element[] = [];
  if (currentInterview && prompts.has("Request Interview")) {
    actions.push(
      <MenuItem
        key={`action-${candidate.id}-request`}
        onClick={() =>
          requestInterview(candidate.candidateId, currentInterview)
        }
      >
        Request Interview
      </MenuItem>,
    );
  }

  if (currentInterview && prompts.has("Mark Interview Complete")) {
    actions.push(
      <MenuItem
        key={`action-${candidate.id}-complete`}
        onClick={() => finishInterview(candidate.candidateId, currentInterview)}
      >
        Mark Interview Complete
      </MenuItem>,
    );
  }

  if (prompts.has("Reject")) {
    actions.push(
      <MenuItem
        key={`action-${candidate.id}-reject`}
        onClick={() => reject(candidate.id)}
      >
        Reject
      </MenuItem>,
    );
  }

  if (prompts.has("Make Offer")) {
    actions.push(
      <MenuItem
        key={`action-${candidate.id}-offer`}
        onClick={() => makeOffer(candidate.id)}
      >
        Make Offer
      </MenuItem>,
    );
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
        <div className="flex-1 flex gap-3">
          <div className="flex flex-col items-start rounded-full relative">
            <Avatar
              image={{ src: candidate.profilePictureLink }}
              name={candidate.name}
              size={48}
            />
          </div>
          <div className="flex flex-col items-start gap-1 flex-1">
            <div className="flex flex-col gap-[2px] items-start">
              <p className="text-base font-semibold text-[#22234A]">
                {candidate.name}
              </p>
              <p className="text-sm font-normal text-[#727E94]">
                {candidate.headline}
              </p>
            </div>
          </div>
        </div>
        {actions.length ? (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton
                className={menuStyle.menuButton}
                shape="circular"
                menuIcon={<ChevronDownRegular />}
                disabled={posting}
              >
                {posting ? "Updating..." : "Take Action"}
              </MenuButton>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>{actions}</MenuList>
            </MenuPopover>
          </Menu>
        ) : null}
        <Button
          appearance="secondary"
          shape="circular"
          onClick={() =>
            navigate(`/opening/${opening?.id}/application/${candidate.id}`)
          }
        >
          <p className="text-sm font-semibold text-[#4E4F6C]">See Profile</p>
        </Button>
      </div>
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
        <OpportunityKeyPoint
          title="Scope"
          value={candidate.agreesWithScope ? targetScope : candidate.scope}
        />
        <OpportunityKeyPoint title="Pay" value={candidate.budget} />
        <OpportunityKeyPoint
          title="First available start date"
          value={
            candidate.agreesWithStartDate
              ? targetStartDateString
              : candidate.earliestStartDate &&
                (dateStringToLocalDate(
                  candidate.earliestStartDate,
                )?.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }) ??
                  "Unknown")
          }
        />
        {transitionsToFullTime ? (
          <OpportunityKeyPoint
            title="Open to full-time?"
            value={candidate.openToFullTime ? "Yes" : "No"}
          />
        ) : null}
      </div>
      <ProgressTracker stages={stages} currentStage={stageIndex} />
    </div>
  );
};

const OpportunityKeyPoint = ({
  title,
  value,
}: {
  title: "Scope" | "Pay" | "First available start date" | "Open to full-time?";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1">
      <p className="text-sm font-medium text-[#727E94]">{title}:</p>
      <p className="text-sm font-semibold text-[#22234A]">{value}</p>
    </div>
  );
};

const NewDialog = ({ loaded }: { loaded: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const dialogStyle = useDialogStyles();

  useEffect(() => {
    setIsOpen(searchParams.get("new") === "true");
  }, [searchParams]);

  return (
    <Dialog
      open={isOpen && loaded}
      onOpenChange={(_evt, data) => setIsOpen(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Opportunity Received!
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <p className="text-base font-medium text-[#22234A]">
              Thank you for sharing a job opportunity! The Venturous team will
              review and reach back out to you for additional information if
              necessary. You&apos;ll be notified once a candidate shortlist is
              ready.
            </p>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <Button className="w-full" appearance="secondary">
                  Close
                </Button>
              </DialogTrigger>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default CompanyOpportunity;
